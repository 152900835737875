<template>
  <q-card bordered class="q-ma-md">
    <q-card-section class="section-title">
      <span class="text-h6" style="color: #777">{{ title }}</span>
    </q-card-section>
    <q-separator />
    <q-card-section>
      <slot />
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: "ProFormRenderCard",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
