<template>
  <div class="ProFormRenderAdditionalInfo">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ProFormRenderAdditionalInfo",
};
</script>

<style scoped>
.ProFormRenderAdditionalInfo {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -25px;
}
</style>
