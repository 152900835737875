<template>
  <el-container>
    <el-main style="overflow: auto !important">
      <q-toolbar
        class="bg-gray-1 text-primary q-my-none shadow-2 light-breadcrumbs"
      >
        <div class="q-pa-none q-gutter-sm pro">
          <q-breadcrumbs>
            <q-breadcrumbs-el
              icon="PROSmart-accountPlus"
              :label="$t('Document.BreadcrumbsAcceptanceOfTermsOfUse')"
            />
          </q-breadcrumbs>
        </div>
      </q-toolbar>

      <div v-if="step === 0">
        <light-weight-registration-acceptance-of-terns-of-use
          @next="showHistory"
        />
      </div>

      <div v-if="step === 1">
        <pro-tender-form-render
          mode="View"
          code="Supplier_Tender_NewLightReg_View"
          :tenderId="tenderId"
          :form-data-promise="formDataPromise()"
        />
        <pro-form-render-additional-info v-if="brFile.length > 0 && showBrFile">
          <pro-form-render-card :title="$t('Form.Field.ACBRC')">
            <q-item
              class="grey-on-hover"
              v-for="file in brFile"
              :key="file.fileId"
            >
              <pro-file :file="file" show-download-button />
            </q-item>
          </pro-form-render-card>
        </pro-form-render-additional-info>

        <div
          class="row justify-end"
          style="padding-right: 10px; padding-bottom: 10px"
        >
          <q-btn
            no-caps
            color="primary"
            :label="$t('edit')"
            @click="step = 2"
            outline
            style="margin-right: 10px"
          />
          <q-btn
            no-caps
            color="primary"
            :label="$t('submit')"
            @click="autoApproval()"
          />
        </div>
      </div>
      <div v-if="step === 2">
        <pro-tender-form-render
          mode="Edit"
          code="S_T_NewLightReg"
          :tenderId="tenderId"
          :form-data-promise="formDataPromise()"
          @pro-form-submit="redirectToHcmDashboard('/MyDocument')"
        />
      </div>
    </el-main>
  </el-container>
</template>

<script>
import ProTenderFormRender from "@/components/PROSmart/FormBuilder/ProTenderFormRender";
import LightWeightRegistrationAcceptanceOfTernsOfUse from "@/components/PROSmart/ActionMenu/LightWeightRegistrationAcceptanceOfTernsOfUse";
import ProFormRenderAdditionalInfo from "@/components/PROSmart/FormBuilder/ProFormRenderAdditionalInfo";
import ProFormRenderCard from "@/components/PROSmart/FormBuilder/ProFormRenderCard";
import ProFile from "@/components/PROSmart/ProFile";

export default {
  name: "LightWeightRegistrationRegisteredBefore",
  components: {
    ProFile,
    ProFormRenderCard,
    ProFormRenderAdditionalInfo,
    LightWeightRegistrationAcceptanceOfTernsOfUse,
    ProTenderFormRender,
  },
  props: {
    tenderId: {
      type: [String, Number],
      required: true,
    },
    tendererId: {
      type: Number,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    hcmUserId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      info: {},
      step: 0,
      brFile: [],
      showBrFile: false,
    };
  },
  created() {
    this.$proSmart.tendererUi
      .getLightWeightRegistrationRegisteredBeforeRecord(this, this.tenderId)
      .then((rs) => {
        if (rs.value !== "") {
          this.updateInfo(
            [
              "yearEstablished",
              "address1",
              "address2",
              "address3",
              "zipCode",
              "registeredCountryRegion",
              "fax",
              "position",
              "tel",
              "brNumber",
            ],
            JSON.parse(rs.value)
          );
        }

        this.brFile = rs.brFile;
      });
  },
  methods: {
    /**
     * @param {Array<string>}keyName
     * @param {object} obj
     */
    updateInfo(keyName, obj) {
      keyName.forEach((b) => {
        if (Object.prototype.hasOwnProperty.call(obj, b)) {
          this.info[b] = obj[b];
        }
      });
    },
    autoApproval() {
      this.$proSmart.tendererUi
        .lightWeightRegistrationRegisteredBeforeAutoApprovalRecord(
          this,
          this.tenderId,
          "S_T_NewLightReg"
        )
        .then((rs) => {
          if (rs.success) {
            this.$alert(
              this.getRes("WorkflowOperation.OperationSucceed"),
              this.getRes("notification"),
              { confirmButtonText: this.getRes("ok") }
            ).then(() => {
              this.$store.dispatch("menu/removeSubMenu", "/MyDocument");
              this.$router.push({ path: "/Dashboard" });
            });
          } else {
            this.$alert(
              "Error",
              this.getRes("WorkflowOperation.Msg_failure1"),
              {
                confirmButtonText: this.getRes("ok"),
              }
            );
          }
        });
    },
    formDataPromise() {
      return new Promise((resolve) => {
        let obj = this.info;
        obj.tenderId = this.tender;
        obj.tendererId = this.tenderer;
        obj.email = this.email;
        obj.tendererHcmCode = this.hcmUserId;

        resolve(obj);
      });
    },
    showHistory() {
      this.step = 1;
      setTimeout(() => (this.showBrFile = true), 1000);
    },
    redirectToHcmDashboard(path) {
      this.$store.dispatch("menu/removeSubMenu", path);
      this.$router.push({ path: "/Dashboard" });
    },
  },
};
</script>

<style lang="scss" scoped>
.light-breadcrumbs {
  .flex {
    flex-direction: row;
  }
}
</style>
