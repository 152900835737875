<template>
  <div>
    <q-card class="q-ma-sm">
      <q-card-section>
        <div class="text-h5">
          {{ $t("Document.Breadcrumbs.AcceptanceOfTermsOfUse") }}
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-sm">
        <div class="row" style="text-align: left">
          <div class="col-1" style="text-align: center">1.</div>
          <div class="col-11">
            <!--Please read our ProSmart Website Terms of Use and Privacy Statement-->
            <!--& Disclaimer of this ProSmart Website before proceeding with your-->
            <!--application for registration. If you continue with the application-->
            <!--and registration process, then this signifies your acceptance of out-->
            <!--ProSmart Website Terms of Use and your agreement to be bound by-->
            <!--them. You cannot apply or register as a supplier if you do not agree-->
            <!--to accept all of the ProSmart Website Terms od Use.-->
            {{ $t("Form.Field.ReadTerms1") }}{{ $t("Form.Field.ReadTerms2")
            }}{{ $t("Form.Field.ReadTerms3") }}
          </div>
        </div>

        <div class="row" style="margin-top: 20px">
          <div class="col-1" style="text-align: center">2.</div>
          <div class="col-11">
            <!--In order to complete the registration process, you must provide some-->
            <!--basic company information in order to register with our ProSmart-->
            <!--Website.-->
            {{ $t("Form.Field.ReadTerm4") }}
          </div>
        </div>
      </q-card-section>
    </q-card>
    <q-form @submit="login">
      <q-card class="q-ma-sm">
        <q-card-section>
          <div class="text-h5">
            {{ $t("Document.Breadcrumbs.Declaration") }}
          </div>
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pt-sm">
          <q-field
            borderless
            dense
            bottom-slots
            :value="termsOfUse"
            ondemand
            :rules="[(val) => val || $t('Form.Message.Error.AcceptTerms2')]"
          >
            <q-checkbox
              v-model="termsOfUse"
              :label="$t('Form.Field.ReadTerm5')"
            />
            <template #error="{ errorMessage }">
              <div style="margin-left: 40px">{{ errorMessage }}</div>
            </template>
          </q-field>
        </q-card-section>
      </q-card>
      <div
        class="row justify-end"
        style="padding-right: 10px; padding-bottom: 10px"
      >
        <q-btn
          color="primary"
          W
          :label="$t('Form.Action.Next')"
          @click="termsOfUse ? $emit('next') : ''"
          no-caps
          type="submit"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
export default {
  name: "LightWeightRegistrationAcceptanceOfTernsOfUse",
  data() {
    return {
      termsOfUse: false,
    };
  },
};
</script>
